import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid, Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { spacing } from "@material-ui/system";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Box as BoxIcon, Map as MapIcon } from "react-feather";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import StandardHeader from "../../components/global/StandardHeader";
import { LeftSidebar as NavFrame } from "layout-blueprints";
import {
  getAllAccountStatusCodes,
  getAllCustomerTypes,
  getAllPropertyPreviews,
  getAllUnitTypes,
} from "../../modules/apl/api/actions";
import GenericStatusTag from "../../modules/apl/components/GenericStatusTag";
import { get as getDevelopers } from "../../modules/developer/api/actions";
import { get as getOwners } from "../../modules/owner/api/actions";
import {
  getAllCommonChargeRelatedDataByPropertyId,
  getByUuid as getPropertyByUuid,
  getPropRiderRatesByPropByUuid,
} from "../../modules/property/api/actions";
import PropertyDetailCard from "../../modules/property/components/DetailCard";
import {
  get as getPropertyManagers,
  getById as getPropertyManagerById,
} from "../../modules/propertyManager/api/actions";
import PropertyManagerDetailCard from "../../modules/propertyManager/components/DetailCard";
import { get as getPropertyManagerStatuses } from "../../modules/propertyManagerStatus/api/actions";
import { get as getPropertyStatuses } from "../../modules/propertyStatus/api/actions";
import { getByPropertyId as getPropertyUtilityByPropertyId } from "../../modules/propertyUtility/api/actions";
import { getByPropertyId as getPropertyGLCodeByPropertyId } from "../../modules/propertyGLCode/api/actions";
import PropertyUtilityDetailCard from "../../modules/propertyUtility/components/DetailCard";
import PropertyGLCodeDetailCard from "../../modules/propertyGLCode/components/DetailCard";
import { getByPropertyId as getUnitsByPropertyId } from "../../modules/unit/api/actions";
import { get as getVendors } from "../../modules/vendor/api/actions";
import PropertyDashboardTabedTables from "./PropertyManagerTabbedTabels";

const Divider = styled(MuiDivider)(spacing);
const IndividualPropertyPage = (props) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
    },
    percentDif: {
      textAlign: "right",
      color: "green",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    StatsIcon: {
      width: "32px",
      height: "32px",
      color: theme.palette.secondary.main,
      position: "absolute",
      right: "16px",
      top: "32px",
    },
    rightSideHeader: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    rightSideHeaderSubMenuToolBar: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "0px",
      padding: "0px",
    },
    SubMenuToolBarIcon: {
      width: "25px",
      height: "25px",
      paddingBotom: "5px",
    },
    orange: {
      //color: theme.palette.getContrastText(deepOrange[500]),
    },
    UserBoxText: {
      textAlign: "center",
    },
    UserProfileDetailsButton: {
      padding: "5px",
    },
    AvatarText: {
      marginTop: "20%",
      height: "128px",
      width: "128px",
      textAlign: "center",
      alignSelf: "center",
    },
    AvatarIcon: {
      display: "inline-block",
      height: "128px",
      width: "128px",
      backgroundColor: "gray",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
    ChartWrapper: {
      height: "200px",
      maxHeight: "200px",
      minHeight: "200px",
    },
    AccountInfoGrid: {
      height: "400px",
      maxHeight: "400px",
      minHeight: "400px",
    },
  }));

  const classes = useStyles();
  const Button = styled(MuiButton)(spacing);

  const onUpdateUnits = () => {
    let propertyId = props.match.params.id;
    props.getUnitsByPropertyId(propertyId, null);
  };

  useEffect(() => {
    let propertyUuid = props.match.params.uuid;
    let propertyId = props.match.params.id;
    let propertyManagerId = props.match.params.propertyManagerId;

    props.getPropRiderRatesByPropByUuid(propertyUuid);
    props.getAllCommonChargeRelatedDataByPropertyId(propertyId);
    props.getPropertyByUuid(propertyUuid);
    props.getPropertyUtilityByPropertyId(propertyId);
    props.getPropertyGLCodeByPropertyId(propertyId);
    props.getPropertyManagerById(propertyManagerId);
    props.getUnitsByPropertyId(propertyId, null);
    props.getAllAccountStatusCodes();
    props.getAllCustomerTypes();
    props.getAllPropertyPreviews();
    props.getOwners();
    props.getVendors();
    props.getDevelopers();
    props.getPropertyManagers();
    props.getPropertyStatuses();
    props.getPropertyManagerStatuses();
    props.getAllUnitTypes();
  }, [props.match.params]);

  const commonUnits = props.units.data.unitsList.items?.filter(unit => unit.commonAccount === 1).length;
  const activeUnits = props.units.data.unitsList.items?.filter(unit => unit.accountStatus === 'Active').length
  const vacantUnits = props.units.data.unitsList.items?.length - activeUnits;

  const UnitCountCard = () => {
    //console.log(props)
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {!props.properties.data.property ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">
                    {props.units.data.unitsList.items.length}
                  </Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Unit Count
                </Typography>
                <BoxIcon className={classes.StatsIcon} />

                <Divider className="mb-2 mt-2" my={1} />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1"> Active </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {activeUnits}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1"> Vacant </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {vacantUnits}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1"> Common </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {commonUnits}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const TotalSqFtCard = () => {
    return (
      <Box position="relative">
        <Card mb={6} pt={2}>
          <CardContent>
            {!props.properties.data.property ? (
              <Skeleton
                variant="rect"
                height="60px"
                style={{ backgroundColor: `#bdbfbe`, padding: 6, margin: 6 }}
              />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  <Box fontWeight="fontWeightRegular">
                    {props.properties.data.property.totalSqFt}
                  </Box>
                </Typography>
                <Typography variant="body2" gutterBottom mt={3} mb={0}>
                  Total Square Ft.
                </Typography>
                <MapIcon className={classes.StatsIcon} />
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  var pageTitle = "Ubil+ Loading Property...";
  // Sets the tab name based on the loaded account being worked on
  if (props.properties.data.property.id) {
    pageTitle = props.properties.data.property.name;
  }
  document.title = pageTitle;

  return (
    <NavFrame page={"IndividualPropertyPage"}>
      <StandardHeader
        title={"Property Management: " + pageTitle}
        appPath={"Property Manager"}
      />
      {props.properties.data.property.outage && (
        <div>
          <Grid item direction="column" container spacing={1}>
            <GenericStatusTag
              customStyles={{ backgroundColor: "red" }}
              title={`Outage Reported as of: ${moment(
                props.properties.data.property.outage.outageStart
              ).format("L LT")}`}
            />
          </Grid>
        </div>
      )}

      <Divider className="mb-3 mt-3" />
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid container item spacing={2} xs={12}>
          <Grid item spacing={2} xs={6}>
            <PropertyDetailCard />
          </Grid>
          <Grid item spacing={2} xs={6}>
            <PropertyManagerDetailCard />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item spacing={2} xs={4}>
            <PropertyGLCodeDetailCard />
            <Divider spacing={2} />
            <PropertyUtilityDetailCard />
          </Grid>
          <Grid item spacing={2} xs={8}>
            <Grid container item spacing={2} xs={12}>
              <Grid item spacing={2} xs={6}>
                <UnitCountCard />
              </Grid>
              <Grid item spacing={2} xs={6}>
                <TotalSqFtCard />
              </Grid>
              <Grid item spacing={2} xs={12}>
                <PropertyDashboardTabedTables
                  propertyUuid={props.match.params.uuid}
                  propertyId={props.match.params.id}
                />
                {/*<UnitTable onUpdate={onUpdateUnits}/>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NavFrame>
  );
};

IndividualPropertyPage.propTypes = {
  user: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  propertyGLCode: PropTypes.object.isRequired,
  unitTypes: PropTypes.object.isRequired,
  propertyUtilities: PropTypes.object.isRequired,

  getUnits: PropTypes.func.isRequired,
  getPropertyByUuid: PropTypes.func.isRequired,
  getPropertyUtilityByPropertyId: PropTypes.func.isRequired,
  getPropertyGLCodeByPropertyId: PropTypes.func.isRequired,
  getPropertyManagerByUuid: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
  getDevelopers: PropTypes.func.isRequired,
  getPropertyManagers: PropTypes.func.isRequired,
  getPropertyStatuses: PropTypes.func.isRequired,
  getPropertyManagerStatuses: PropTypes.func.isRequired,
  getPropRiderRatesByPropByUuid: PropTypes.func.isRequired,
  getAllPropertyPreviews: PropTypes.func.isRequired,
  getAllAccountStatusCodes: PropTypes.func.isRequired,
  getAllCustomerTypes: PropTypes.func.isRequired,
  getAllCommonChargeRelatedDataByPropertyId: PropTypes.func.isRequired,
  getAllUnitTypes: PropTypes.func.isRequired,
};

// Component State
function IndividualPropertyPageState(state) {
  return {
    user: state.user,
    apl: state.apl,
    properties: state.properties,
    units: state.units,
    propertyGLCode: state.propertyGLCode,
    unitTypes: state.unitTypes,
    propertyUtilities: state.propertyUtilities,
  };
}

export default connect(IndividualPropertyPageState, {
  getUnitsByPropertyId,
  getPropertyByUuid,
  getPropertyUtilityByPropertyId,
  getPropertyManagerById,
  getOwners,
  getVendors,
  getDevelopers,
  getPropertyManagers,
  getPropertyStatuses,
  getPropertyManagerStatuses,
  getAllUnitTypes,
  getPropRiderRatesByPropByUuid,
  getPropertyGLCodeByPropertyId,
  getAllPropertyPreviews,
  getAllAccountStatusCodes,
  getAllCustomerTypes,
  getAllCommonChargeRelatedDataByPropertyId,
})(IndividualPropertyPage);
