import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import {
  Button as MuiButton,
  Card,
  CardContent,
  Grid,
  Typography,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormControl,

  TextField as MuiTextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { put, post } from "../api/actions";
import { isNotEmpty } from "../../../utils/Utils";
import { getByUuid as getProperty } from "../api/actions"
import { get as getProperties } from "../api/actions"
import { getById as getPropertyManager } from "modules/propertyManager/api/actions";
import StatusTag from "modules/apl/components/StatusTag";
import { useHistory } from "react-router-dom";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);

const PropertyForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const dispatch = useDispatch();

  const [property, setProperty] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);

  const [isRemote, setIsRemote] = React.useState(props.properties.data.property.isRemote == 1 ? true : false);

  const toggleIsRemote = () => {
    var newProperty = property;
    if (isRemote) {
      newProperty.isRemote = 0;
    }
    else {
      newProperty.isRemote = 1;
    }
    setIsRemote(!isRemote);
    setProperty(newProperty);
    console.log("property");
    console.log(property);
  };

  useEffect(() => {
    if (!isNotEmpty(property) && props.properties.data.property.id) {
      setIsRemote(props.properties.data.property.isRemote);
      setProperty(props.properties.data.property);
    }
  }, [property, props.properties.data.property]);

  const onFieldChange = (e) => {
    if (submitted) setSubmitted(false);
    var newProperty = property;
    newProperty[e.target.name] = e.target.value;
    setProperty(newProperty);
  };

  function checkEmpty(value) {
    return (value != null && value != "");
  }

  const history = useHistory()
  useEffect(() => {
    if (props.properties.newlyAddedProperty != null) {
      window.open(
        "/property/" + props.properties.newlyAddedProperty.uuid + "/" + props.properties.newlyAddedProperty.id + "/" + props.properties.newlyAddedProperty.propertyManagerId
      );
    }
  }, [props.properties.newlyAddedProperty]);


  const validate = () => {
    var newProperty = {
      name: property.name ? property.name : "",
      developerId: property.developerId ? property.developerId : "",
      address: property.address ? property.address : "",
      isRemote: property.isRemote ? property.isRemote : 0,

      //ownerId: property.ownerId ? property.ownerId : "",
      propertyManagerId: property.propertyManagerId
        ? property.propertyManagerId
        : "",
      city: property.city ? property.city : "",
      state: property.state ? property.state : "",
      zip: property.zip ? property.zip : "",
      statusId: property.statusId ? property.statusId : "",
    };

    const values = [];
    Object.keys(newProperty).forEach(function (key) {
      let val = newProperty[key];
      if (key != "isRemote") {
        values.push(val);
      }
    });

    newProperty.id = property.id;
    newProperty.uuid = property.uuid;

    setProperty(newProperty);

    if (values.every(checkEmpty)) {
      return newProperty;
    } else {
      return -1;
    }
  };

  const save = async () => {
    const newProperty = validate();
    console.log(newProperty)
    if (newProperty == -1) {
      setSubmitted(true);
      return;
    }

    if (!newProperty.id) {
      props.post(newProperty).then(() => {
        props.onUpdate();
        props.getProperties();
      });
    }
    else {
      //console.log(newProperty)
      props.onUpdate();
      await props.put(newProperty);
      await props.getProperty(property.uuid)
      await props.getPropertyManager(property.propertyManagerId)
      history.replace(`/property/${newProperty?.uuid}/${newProperty?.id}/${newProperty?.propertyManagerId}`);
    }

  };

  const classes = useStyles();

  if (
    !property ||
    props.propertyIsLoading ||
    props.developers.isLoading ||
    props.propertyManagers.isLoading ||
    props.propertyStatuses.isLoading ||
    props.properties.isLoading
  ) {
    return (
      <Card mb={6}>
        <CardContent>
          {/* TODO loading bars */}
          <Typography variant="h3" gutterBottom>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        {/* Property Details */}
        <Grid container spacing={2}>
          <Grid container item>
            <Grid item xs={12}>
              <TextField
                label="Property Name"
                type="text"
                defaultValue={property.name}
                required
                onChange={onFieldChange}
                name="name"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={property["name"] == "" && submitted}
                helperText={property["name"] == "" ? "Name required" : ""}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              type="text"
              defaultValue={property.address}
              required
              onChange={onFieldChange}
              name="address"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={property["address"] == "" && submitted}
              helperText={property["address"] == "" ? "Address required" : ""}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="City"
                type="text"
                onChange={onFieldChange}
                defaultValue={property.city}
                name="city"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={property["city"] == "" && submitted}
                helperText={
                  property["city"] == "" ? "City required" : "" && submitted
                }
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="State"
                type="text"
                onChange={onFieldChange}
                defaultValue={property.state}
                name="state"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={property["state"] == "" && submitted}
                helperText={
                  property["state"] == "" ? "State required" : "" && submitted
                }
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <TextField
                label="Zip"
                type="number"
                onChange={onFieldChange}
                defaultValue={property.zip}
                name="zip"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={property["zip"] == ""}
                helperText={property["zip"] == "" ? "Zip required" : ""}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Default Developer
                </InputLabel>
                <Select
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  defaultValue={property.developerId}
                  onChange={onFieldChange}
                  name="developerId"
                  error={property["developerId"] == "" && submitted}
                  helperText={
                    property["developerId"] == "" ? "Developer required" : ""
                  }
                >
                  {[props.developers.data.developersList.items.map(
                    (item, index) => (
                      <MenuItem id="AccountStatusOption1" value={item.id}>
                        {item.name}
                      </MenuItem>
                    )
                  )]}
                </Select>
              </FormControl>
            </Grid>
            {/*<Grid item md={6} xs={12}>*/}
            {/*  <FormControl variant="filled" fullWidth>*/}
            {/*    <InputLabel id="selectedAccountStatusDropDownLabel">*/}
            {/*      Owner*/}
            {/*    </InputLabel>*/}
            {/*    <Select*/}
            {/*      labelId="selectedAccountStatusDropDownLabel"*/}
            {/*      id="selectedAccountStatusDropDown"*/}
            {/*      defaultValue={property.ownerId}*/}
            {/*      onChange={onFieldChange}*/}
            {/*      name="ownerId"*/}
            {/*      error={property["ownerId"] == "" && submitted}*/}
            {/*      helperText={*/}
            {/*        property["ownerId"] == "" ? "Owner required" : ""*/}
            {/*      }*/}
            {/*    >*/}
            {/*      {[props.owners.data.ownersList.items.map(*/}
            {/*        (item, index) => (*/}
            {/*          <MenuItem id="AccountStatusOption1" value={item.id}>*/}
            {/*            {item.name}*/}
            {/*          </MenuItem>*/}
            {/*        )*/}
            {/*      )]}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}
            <Grid item md={6} xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Default Property Manager
                </InputLabel>
                <Select
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  defaultValue={property.propertyManagerId}
                  onChange={onFieldChange}
                  name="propertyManagerId"
                  error={property["propertyManagerId"] == ""}
                  helperText={
                    property["propertyManagerId"] == ""
                      ? "Property Manager required"
                      : ""
                  }
                >
                  {[props.propertyManagers.data.propertyManagersList.items.map(
                    (item, index) => (
                      <MenuItem id="AccountStatusOption1" value={item.id}>
                        {item.name}  {item.developerId &&  <StatusTag
                                                            className="ml-3"
                                                            type={"Default"}
                                                            label={"Developer"}
                                                          />
                                                          }
                      </MenuItem>
                    )
                  )]}
                </Select>
              </FormControl>
            </Grid>
            {/*<Grid item sm={4} xs={6}>*/}
            {/*  <FormGroup row>*/}
            {/*    <FormControlLabel*/}
            {/*      control={*/}
            {/*        <Checkbox*/}
            {/*          checked={isRemote}*/}
            {/*          onChange={toggleIsRemote}*/}
            {/*          name="isRemote"*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Remote"*/}
            {/*    />*/}
            {/*  </FormGroup>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid container item spacing={2}>
            {/*<Grid item xs={4}>
              <TextField
                label="Total Units"
                type="text"
                defaultValue={property.totalUnits}
                onChange={onFieldChange}
                name="totalUnits"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                //error={property["totalUnits"] == ""}
                helperText={
                  property["totalUnits"] == "" ? "Total Units required" : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Total SQ FT"
                type="text"
                defaultValue={property.totalSqFt}
                onChange={onFieldChange}
                name="totalSqFt"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                //error={property["totalSqFt"] == ""}
                helperText={
                  property["totalSqFt"] == "" ? "Total Sq Ft required" : ""
                }
              />
              </Grid>*/}
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectedAccountStatusDropDownLabel">
                  Status
                </InputLabel>
                <Select
                  labelId="selectedAccountStatusDropDownLabel"
                  id="selectedAccountStatusDropDown"
                  defaultValue={property.statusId}
                  name="statusId"
                  onChange={onFieldChange}
                  error={property["statusId"] == "" && submitted}
                  helperText={
                    property["statusId"] == "" ? "Status required" : ""
                  }
                >
                  {props.propertyStatuses.data.propertyStatusesList.items.length > 0 ? [
                    props.propertyStatuses.data.propertyStatusesList.items.map(
                      (item, key) => {
                        return (
                          <MenuItem value={item.id} id={key}>
                            {item.description}
                          </MenuItem>
                        );
                      }
                    )
                  ] : [
                    <MenuItem value={-1}>
                      No Property Status Types Found
                    </MenuItem>
                  ]}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item md={3} xs={6}>
              <FormControlLabel
                control={<Checkbox name="checkedA" />}
                label="Customer-Initiated Move-Ins"
                labelPlacement="top"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControlLabel
                control={<Checkbox name="checkedA" />}
                label="Customer-Initiated Move-Outs"
                labelPlacement="top"
              />
            </Grid> */}
          </Grid>

          <Grid container item spacing={2} justify={"flex-end"}>
            <Grid item>
              <MuiButton variant="contained" onClick={save} color="secondary">
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Component Properties
PropertyForm.propTypes = {
  apl: PropTypes.object.isRequired,
  propertyManagers: PropTypes.object.isRequired,
  developers: PropTypes.object.isRequired,
  propertyStatuses: PropTypes.object.isRequired,
  put: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  owners: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  property: PropTypes.func.isRequired,
  getPropertyManager: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired
};

function PropertyFormState(state) {
  return {
    apl: state.apl,
    propertyManagers: state.propertyManagers,
    developers: state.developers,
    owners: state.owners,
    propertyStatuses: state.propertyStatuses,
    properties: state.properties,
  };
}
export default connect(PropertyFormState, { put, post, getProperty, getPropertyManager, getProperties })(PropertyForm);
