//React Components and Hooks
import Button from "@material-ui/core/Button";
import { Divider as MuiDivider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
//Material UI Functions
import { makeStyles, useTheme } from "@material-ui/core/styles";
//Material UI Components
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import StandardHeader from "../../components/global/StandardHeader";
//Custom Components
import { LeftSidebar as NavFrame } from "layout-blueprints";
import CurrentBillBatchesManagerTable from "../../modules/billing/components/CurrentBillBatchesManagerTable";
import moment from "moment";
// actions
import {
  createNewMoveIn,
  deleteMove,
  editMove,
  fetchMoves,
  getMoveOutsInDateRange,
  getMoveInsInDateRange,
  processMoves,
  setTabState,
  updateBatchMoves,
} from "../../modules/moves/api/actions";
import { get as getProperties } from "../../modules/property/api/actions";
import {put as updateUnit} from "../../modules/unit/api/actions";
// snackbar
import { setSnackbar } from "../../modules/snackbar/api/snackbar";
import LegendBox from "./components/LegendBox";
//import Moves from "./components/Moves";
import "./index.css";
// modals
import ManualMoveInModal from "./modals/ManualMoveIn";

// Ui components:

import MoveOutsTable from "../../modules/moves/components/MoveOutsTable";
import MoveInsTable from "../../modules/moves/components/MoveInsTable";

const Divider = styled(MuiDivider)(spacing);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pl={-1}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

//UTILS

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
  loadingIcon: {
    marginLeft: "50%",
    left: "-5",
  },
  mmiButton: {
    margin: "0 auto",
  },
}));

const MovesPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [mmiOpen, setMMIOpen] = useState(false);

  const [preservedMoveIns, setPreservedMoveIns] = useState([]);
  const [preservedMoveOuts, setPreservedMoveOuts] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpdateBatchMoves = async (uuids, type, keyValPair) => {
    try {
      await props.updateBatchMoves(uuids, type, keyValPair);
      dispatch(
        setSnackbar(true, "success", "Successfully updated selected moves.")
      );
      if (value == 0) {
        await props.getMoveOutsInDateRange(3);
      }
      else {
        await props.getMoveInsInDateRange(2);
      }
    } catch (err) {
      dispatch(setSnackbar(true, "error", "Failed to update moves."));
    }
  };

  const handleProcessMoves = async (uuids, type) => {
    // keep these to preserve in case of err
    // const originalUuids = Object.assign([], uuids);
    // const originalType = type;

    try {
      const moves =
        type == "movein" ? props.moves.data.moveIns : props.moves.data.moveOuts;

      let areMovesNotReadyButSelected = false;
      moves.forEach((move) => {
        uuids.forEach((uuid) => {
          if (move.uuid == uuid && !move.readyForProcess) {
            dispatch(
              setSnackbar(
                true,
                "error",
                "Please unselect moves that are NOT ready to process."
              ),
              true
            );
            // preserveMoves(originalUuids, originalType);
            areMovesNotReadyButSelected = true;
          }
        });
      });

      if (areMovesNotReadyButSelected) return 1;

      const movesToProcess = [];
      const moveUuidMatchTable = {}; // use this to prevent duplicate moveins from being sent

      moves.forEach((move) => {
        uuids.forEach((uuid) => {
          if (uuid == move.uuid) {
            // set initial state of match table for this uuid after the check (this enables first time matches, but prevents second time matches)
            if (!moveUuidMatchTable[uuid]) {
              moveUuidMatchTable[uuid] = 1;
              movesToProcess.push(move);
            }
          }
        });
      });

      if (!movesToProcess.length)
        dispatch(setSnackbar(true, "error", "No matching move ins selected."));

      await props.processMoves(movesToProcess, type);
      // dispatch(
      //   setSnackbar(true, "success", "Successfully processed selected moves.")
      // );
    } catch (err) {
      dispatch(setSnackbar(true, "error", "Failed to process moves."));
    }
  };

  /*const asyncEffect = async () => {
    try {
      await props.fetchMoves(6);
      props.getProperties();
    } catch (err) {
      dispatch(setSnackbar(true, "error", "Failed to fetch moves."));
    }
  };*/

  const openManualMoveInModal = () => {
    setMMIOpen(true);
  };

  const handleMMIClose = () => {
    setMMIOpen(false);
  };

  const preserveMoves = (uuids, type) => {
    if (type == "movein") setPreservedMoveIns(uuids);
    else setPreservedMoveOuts(uuids);
  };

  const submitNewMoveIn = async (moveIn, isBillingSame, unitInfo) => {
    try {
      moveIn.meterRead = Number(moveIn.meterRead);
      let payload = isBillingSame
        ? Object.assign({}, moveIn, {
            billingAddress1: moveIn.address1,
            billingAddress2: moveIn.address2,
            billingState: moveIn.state,
            billingCity: moveIn.city,
            billingZip: moveIn.zip,
          })
        : moveIn;

      await props.createNewMoveIn(payload);
      await props.getMoveInsInDateRange(2);
      if (unitInfo?.uuid) await props.updateUnit(unitInfo);
    } catch (err) {
      
    }
  };
  useEffect(() => {
    if (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) {
      props.getProperties();
    }
  }, [props.user]);
  document.title = "Ubil+ Move Ins/Outs";

 if (props.user.details.billing || props.user.details.administration || props.user.details.isSuperUser) {
  return (
    <NavFrame page={"MOVE INS/OUTS"}>
      <StandardHeader title="Customer Moves"
        rightSideToolBar={
          <Grid container spacing={2} direction="row" xs={12}>
            <Grid item xs={6}>
              <Button
                className={classes.mmiButton}
                variant="contained"
                color="secondary"
                onClick={openManualMoveInModal}
                size="small"
                style={{ maxHeight: 40 }}
              >
                MANUAL MOVE-IN
              </Button>
            </Grid>
            <Grid container spacing={2} xs={6}>
              <Grid item xs={12}>
                <LegendBox
                  color="#F08080"
                  text="Usage"
                  helper="Usage is less than or equal to 0, or over 1,500."
                />
              </Grid>
              <Grid container item xs={12}>
                <p>(Prov. EST/ACT) - Meter Read was actually input or system estimated.</p>
              </Grid>
            </Grid>
            {/*<Grid container spacing={2} xs={6}>
              <Grid item xs={12}>
                <LegendBox
                  color="#ADD8E6"
                  text="Account Holder"
                  helper="Duplicate account name and email."
                />
              </Grid>
              <Grid item xs={12}>
                <LegendBox
                  color="#FFFACD"
                  text="Current Tenant"
                  helper="Current tenant is a customer."
                />
              </Grid>
              <Grid item xs={12}>
                <LegendBox
                  color="#F08080"
                  text="Usage"
                  helper="Usage is less than or equal to 0, or over 1,500."
                />
              </Grid>
              <Grid container item xs={12}>
                <p>(Prov. EST/ACT) - Meter Read came from PM or customer.</p>
        </Grid>
          </Grid>
          */}
          </Grid>

        } />
      < Divider className="mb-3 mt-3" />
      <ManualMoveInModal
        properties={props.properties}
        handleClose={handleMMIClose}
        open={mmiOpen}
        submitNewMoveIn={submitNewMoveIn}
      />
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Account Management Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Move Outs" {...a11yProps(0)} />
            <Tab label="Move Ins" {...a11yProps(1)} />
            <Tab label="Move Billing Batches" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MoveOutsTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MoveInsTable />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CurrentBillBatchesManagerTable isMovesTable={true} />
        </TabPanel>
      </div>
    </NavFrame >
  );
}
    return (
    <NavFrame page={"MOVE INS/OUTS"}>
      <StandardHeader title="Customer Moves" />
      <Divider className="mb-3 mt-3" />
      <h3>You do not have privileges for moves page</h3>
    </NavFrame>

  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    moves: state.moves,
    properties: state.properties,
  };
};

export default connect(mapStateToProps, {
  getProperties,
  editMove,
  fetchMoves,
  deleteMove,
  updateBatchMoves,
  processMoves,
  getMoveOutsInDateRange,
  getMoveInsInDateRange,
  createNewMoveIn,
  setTabState,
  updateUnit
})(MovesPage);
