import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  Chip,
  Box,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
} from "@material-ui/core";
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { spacing } from "@material-ui/system";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Autocomplete from '@mui/material/Autocomplete';

const Select = styled(MuiSelect)(spacing);
const TextField = styled(MuiTextField)(spacing);

const getPropertyNameToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].name;
};

const getPropertyIdToUuid = (properties, uuid) => {
  let ind = properties.findIndex((elem) => elem.uuid == uuid);
  return properties[ind].id;
};

export default (props) => {
  const actionProp = props.action;
  const handleClose = props.handleClose;
  const properties = props.properties.data.propertiesList.items;

  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState([]);
  const [unitInfo, setUnitInfo] = useState({
    uuid: "",
    bedrooms: null,
    baths: null,
  });
  const [areUnitsLoading, setAreUnitsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [currentOccupant, setCurrentOccupant] = useState();
  const [unitAutoComplete, setUnitsAutoComplete] = useState([]);


  // used as the base for validation
  const [newMoveObjTemp, setNewMoveObjTemp] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone1: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    billingAddress1: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    propertyUuid: "",
    unitUuid: "",
    moveInDate: "",
    ssn: "",
    eBills: 0,
    printedBills: 0,
    userAccountExists: 0,
  });

  const [state, setState] = React.useState({
    billingSame: false,
  });

  const [readTypeChecked, setReadTypeChecked] = useState("");
  const [useServiceAddress, setUseServiceAddress] = React.useState(true);

  const handleReadTypeChange = (type) => {
    if (readTypeChecked == type) setReadTypeChecked("");
    else setReadTypeChecked(type);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleUseServiceAddressChange = (event) => {
    if (useServiceAddress) {
      setUseServiceAddress(false);
    }
    else {
      setUseServiceAddress(true);
    }
  };

  const onFieldChange = async (e) => {
    console.log(e);
    if (e) {
      setNewMoveObjTemp({ ...newMoveObjTemp, [e.target.name]: e.target.value });
      if(e.target.name == "unitUuid") {
        try {
          let response = await axios.get(`unit-current-occupant/${e.target.value}`)
          setCurrentOccupant(response.data[0])
        }catch(e) {
          console.log(e);
        }

      }

    }
    // validation stuff
    if (submitted) setSubmitted(false);
  };

  const onUnitFieldsChange = (e) => {
    let { name, value } = e.target;
    if (Number(value) < 0) {
      value = '';
    }

    if (name && value && unit?.uuid) {
      setUnitInfo({
        ...unitInfo,
        uuid: unit.uuid,
        [name]: value,
      })
    }
  };

  const onCheckboxChange = (e) => {
    setNewMoveObjTemp({
      ...newMoveObjTemp,
      [e.target.name]: e.target.checked == true ? 1 : 0,
    });
  };

  function checkEmpty(value) {
    return value != null && value != "" && value != 0;
  }

  const validate = () => {
    let possibleBillingAddress = {};
    if (!useServiceAddress) {
      possibleBillingAddress = state.billingSame
        ? Object.assign(
          {},
          {
            billingAddress1: newMoveObjTemp.address1,
            billingAddress2: newMoveObjTemp.address2,
            billingState: newMoveObjTemp.state,
            billingCity: newMoveObjTemp.city,
            billingZip: newMoveObjTemp.zip,
          }
        )
        : {};
    }
    else {
      let selectedUnitIndex = units.findIndex(object => {
        return object.uuid === newMoveObjTemp.unitUuid;
      });
      if (selectedUnitIndex != -1) {
        console.log("possibleBillingAddress = service address");
        possibleBillingAddress =
        {
          address1: units[selectedUnitIndex].address1,
          address2: units[selectedUnitIndex].address2 || "",
          city: units[selectedUnitIndex].city,
          state: units[selectedUnitIndex].state,
          zip: units[selectedUnitIndex].zip,
          billingAddress1: units[selectedUnitIndex].address1,
          billingAddress2: units[selectedUnitIndex].address2 || '',
          billingState: units[selectedUnitIndex].state,
          billingCity: units[selectedUnitIndex].city,
          billingZip: units[selectedUnitIndex].zip,
        }
      }

    }


    console.log("possibleBillingAddress: ", possibleBillingAddress);

    let newMoveInObjState = Object.assign(
      {},
      newMoveObjTemp,
      possibleBillingAddress
    );
    var moveInObjToValidate = Object.assign(
      {},
      newMoveInObjState
    );
    // remove unnecessary fields from being checked
    if (moveInObjToValidate["meterRead"]) delete moveInObjToValidate["meterRead"];
    if ( "address2" in moveInObjToValidate){

       delete moveInObjToValidate["address2"];

    }
    delete moveInObjToValidate["billingAddress2"];
    delete moveInObjToValidate["eBills"];
    delete moveInObjToValidate["printedBills"];
    delete moveInObjToValidate["userAccountExists"];
    const values = [];

    Object.keys(moveInObjToValidate).forEach(function (key) {
      let val = moveInObjToValidate[key];
      console.log(`Key is:  ${key}. Val is: ${val}`);
      values.push(val);
    });

    if (values.every(checkEmpty)) {
      console.log("validate success");
      return newMoveInObjState;
    } else {
      console.log("validation fail");
      console.log("newMoveObjTemp firstName is: ", newMoveInObjState["firstName"]);
      return -1;
    }
  };

  const action = (moveInObj, isBillingSame, unitInfo) => {
    const newMoveIn = validate();

    if (newMoveIn == -1) {
      console.log("setting submitted to true");
      setSubmitted(true);
      return;
    }

    actionProp(newMoveIn, isBillingSame, unitInfo);
    handleClose();
  };

  const handlePropertyChange = (uuid, event) => {
    // reset units and info
    setUnit(null)
    setUnitInfo({
      uuid: "",
      bedrooms: null,
      baths: null,
    });

    if (properties && properties.length) {
      onFieldChange(event);
      const propertyId = getPropertyIdToUuid(properties, uuid);
      setAreUnitsLoading(true);

      axios
        .get(`/unit/property/${propertyId}`)
        .then((res) => {

          const units = {
            options: res.data,
            getOptionLabel: (option => `${option.address1} ${option.address2 || ""}`)
          }
          setUnits(res.data);
          setUnitsAutoComplete(units)

          
          setAreUnitsLoading(false);
        })
        .catch(console.log);
    }
  };

  const fetchInitialUnits = () => {
    if (!isEmpty(newMoveObjTemp) && newMoveObjTemp.propertyUuid)
      handlePropertyChange(newMoveObjTemp.propertyUuid);
  };

  useEffect(() => {
    fetchInitialUnits();
  }, []);

  return (
    <div>
      <Card>
        <CardContent>
          {/* PropertyManager Details */}
          <Grid container spacing={2}>
            <h3>Contact Info:</h3>
            <Grid container item spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="First Name"
                  type="text"
                  onChange={onFieldChange}
                  name="firstName"
                  defaultValue={newMoveObjTemp.firstName}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={newMoveObjTemp["firstName"] == "" && submitted}
                  helperText={
                    newMoveObjTemp["firstName"] == ""
                      ? "First name required."
                      : "" && submitted
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Last Name"
                  type="text"
                  onChange={onFieldChange}
                  name="lastName"
                  defaultValue={newMoveObjTemp.lastName}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={newMoveObjTemp["lastName"] == "" && submitted}
                  helperText={
                    newMoveObjTemp["lastName"] == ""
                      ? "Last name required."
                      : "" && submitted
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="SSN or EIN"
                  type="password"
                  onChange={onFieldChange}
                  name="ssn"
                  defaultValue={newMoveObjTemp.ssn}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    (newMoveObjTemp["ssn"] == 0 ||
                      newMoveObjTemp["ssn"] == "") &&
                    submitted
                  }
                  helperText={
                    newMoveObjTemp["ssn"] == 0 || newMoveObjTemp["ssn"] == ""
                      ? "SSN required."
                      : "" && submitted
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Email"
                  type="text"
                  onChange={onFieldChange}
                  name="email"
                  defaultValue={newMoveObjTemp.email}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={newMoveObjTemp["email"] == "" && submitted}
                  helperText={
                    newMoveObjTemp["email"] == ""
                      ? "Email required."
                      : "" && submitted
                  }
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Phone 1"
                type="text"
                onChange={onFieldChange}
                name="phone1"
                defaultValue={newMoveObjTemp.phone1}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={newMoveObjTemp["phone1"] == "" && submitted}
                helperText={
                  newMoveObjTemp["phone1"] == ""
                    ? "Phone required."
                    : "" && submitted
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Phone 2"
                type="text"
                onChange={onFieldChange}
                name="phone2"
                defaultValue={newMoveObjTemp.phone2}
                //required
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newMoveObjTemp.eBills ? true : false}
                    onChange={onCheckboxChange}
                    name="eBills"
                    value="E-Bills"
                  />
                }
                label="E-Bills"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newMoveObjTemp.printedBills ? true : false}
                    onChange={onCheckboxChange}
                    name="printedBills"
                    value="Print Bills"
                  />
                }
                label="Print Bills"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newMoveObjTemp.userAccountExists ? true : false}
                    onChange={onCheckboxChange}
                    name="userAccountExists"
                    value="User Exists"
                  />
                }
                label="User Exists"
              />
            </Grid>

            <br />
            <h3>Unit Info:</h3>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel id="property">Property</InputLabel>
                  <Select
                    labelId="property"
                    id="propertyUuid"
                    value={newMoveObjTemp.propertyUuid || "none"}
                    onChange={(e) => handlePropertyChange(e.target.value, e)}
                    name="propertyUuid"
                    error={newMoveObjTemp["propertyUuid"] == "" && submitted}
                  >[
                    <MenuItem value={"none"}>None</MenuItem>
                    {properties.map((item, index) => (
                      <MenuItem value={item.uuid} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                    ]
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  {/* <InputLabel id="unit">Unit</InputLabel> */}
                  {/* <Select
                    labelId="unit"
                    id="unitUuid"
                    onChange={onFieldChange}
                    value={newMoveObjTemp.unitUuid || "none"}
                    name="unitUuid"
                    error={newMoveObjTemp["unitUuid"] == "" && submitted}
                  >
                    {(units && units.length > 0) ?
                      [
                        <MenuItem value={"none"}>None</MenuItem>,
                        units.map((item, index) => {
                          let address2 = !item.address2 ? "" : item.address2;
                          return (
                            <MenuItem value={item.uuid} key={index}>
                              {item.address1 + address2}
                            </MenuItem>
                          );
                        })
                      ]
                      :
                      [
                        <MenuItem value={-1}>No Units Found</MenuItem>
                      ]
                    }
                  </Select> */}
                  {(units && units.length > 0) ?
                    <Autocomplete
                    {...unitAutoComplete}
                     disablePortal
                     name="unitUuid"
                     error={newMoveObjTemp["unitUuid"] == "" && submitted}
                     onChange={async (event, newValue) => {
                      if(newValue?.uuid) {
                        setNewMoveObjTemp({ ...newMoveObjTemp, "unitUuid": newValue.uuid });
                        setUnit(newValue)
                        setUnitInfo({
                          uuid: newValue.uuid,
                          bedrooms: newValue?.bedrooms || null,
                          baths: newValue?.baths || null
                        })
                          try {
                            let response = await axios.get(`unit-current-occupant/${newValue.uuid}`)
                            setCurrentOccupant(response.data[0])
                          }
                          catch(e){
                            console.log(e);
                          }
                     }
                     else 
                      setCurrentOccupant({})
                     }}
            
                     size="small"
                     renderInput={(params) => <TextField  {...params} label="Unit" />}
                   />: null}
                  {(currentOccupant?.accountNum && currentOccupant?.customerTypeId == 4) && 
                  <Box sx={{ m: 2 }}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={`Current Occupant - PM: ${currentOccupant.accountNum}`} style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: "100%",
                      fontWeight: "bolder",
                    }} />
                    </Stack>
                    </Box>
                  }

                {(currentOccupant?.accountNum && currentOccupant?.customerTypeId != 4) && 
                <Box sx={{ m: 2 }}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={`Current Occupant - Tenant: ${currentOccupant.accountNum}`}style={{
                        backgroundColor: "red",
                        color: "white",
                        fontSize: "100%",
                        fontWeight: "bolder",
                      }} />
                    </Stack>
                    </Box>
                  }
                </FormControl>
              </Grid>
              {unit?.uuid && <>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Bedrooms"
                    type="number"
                    onChange={onUnitFieldsChange}
                    name="bedrooms"
                    value={unitInfo.bedrooms}
                    disabled={unit?.bedrooms != null}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Bathrooms"
                    type="number"
                    onChange={onUnitFieldsChange}
                    name="baths"
                    value={unitInfo.baths}
                    disabled={unit?.baths != null}
                    //required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>}
            </Grid>
            <br />
            <h3>Address Info:</h3>
            {useServiceAddress ?
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useServiceAddress}
                        onChange={handleUseServiceAddressChange}
                        name="serviceAddressSame"
                      />
                    }
                    label="Use service address as contact and billing info"
                  />
                </Grid>
              </Grid>
              :
              <>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useServiceAddress}
                          onChange={handleUseServiceAddressChange}
                          name="serviceAddressSame"
                        />
                      }
                      label="Use service address as contact and billing info"
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <h5>Mailing Address:</h5>
                  <Grid item xs={12} />
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Address 1"
                      type="text"
                      onChange={onFieldChange}
                      name="address1"
                      defaultValue={newMoveObjTemp.address1}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["address1"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["address1"] == ""
                          ? "Address required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="address 2"
                      type="text"
                      onChange={onFieldChange}
                      name="address2"
                      defaultValue={newMoveObjTemp.address2}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="City"
                      type="text"
                      onChange={onFieldChange}
                      name="city"
                      defaultValue={newMoveObjTemp.city}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["city"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["city"] == ""
                          ? "City required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <TextField
                      label="State"
                      type="text"
                      onChange={onFieldChange}
                      name="state"
                      defaultValue={newMoveObjTemp.state}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["state"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["state"] == ""
                          ? "State required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <TextField
                      label="Zip"
                      type="text"
                      onChange={onFieldChange}
                      name="zip"
                      defaultValue={newMoveObjTemp.zip}
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["zip"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["zip"] == ""
                          ? "Zip required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.billingSame}
                      onChange={handleChange}
                      name="billingSame"
                    />
                  }
                  label="Billing Address Same as Mailing"
                />
                <Grid item xs={12} />
                <h5>Billing Address:</h5>
                <Grid container item spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Billing Address 1"
                      type="text"
                      onChange={onFieldChange}
                      name="billingAddress1"
                      disabled={state.billingSame}
                      value={
                        state.billingSame
                          ? newMoveObjTemp.address1
                          : newMoveObjTemp.billingAddress1
                      }
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["billingAddress1"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["billingAddress1"] == ""
                          ? "Billing Address required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Billing Address 2"
                      type="text"
                      onChange={onFieldChange}
                      name="billingAddress2"
                      disabled={state.billingSame}
                      value={
                        state.billingSame
                          ? newMoveObjTemp.address2
                          : newMoveObjTemp.billingAddress2
                      }
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Billing City"
                      type="text"
                      onChange={onFieldChange}
                      name="billingCity"
                      disabled={state.billingSame}
                      value={
                        state.billingSame
                          ? newMoveObjTemp.city
                          : newMoveObjTemp.billingCity
                      }
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["billingCity"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["billingCity"] == ""
                          ? "Billing City required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <TextField
                      label="Billing State"
                      type="text"
                      onChange={onFieldChange}
                      name="billingState"
                      disabled={state.billingSame}
                      value={
                        state.billingSame
                          ? newMoveObjTemp.state
                          : newMoveObjTemp.billingState
                      }
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["billingState"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["billingState"] == ""
                          ? "Billing State required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <TextField
                      label="Billing Zip"
                      type="text"
                      onChange={onFieldChange}
                      name="billingZip"
                      disabled={state.billingSame}
                      value={
                        state.billingSame
                          ? newMoveObjTemp.zip
                          : newMoveObjTemp.billingZip
                      }
                      //required
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={newMoveObjTemp["billingZip"] == "" && submitted}
                      helperText={
                        newMoveObjTemp["billingZip"] == ""
                          ? "Billing Zip required."
                          : "" && submitted
                      }
                    />
                  </Grid>
                </Grid>
              </>
            }
            <h3>Move Info:</h3>
            <Grid container item spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Move In Date"
                  type="date"
                  onChange={onFieldChange}
                  name="moveInDate"
                  defaultValue={moment(newMoveObjTemp.moveInDate).format(
                    "YYYY-MM-DD hh:mm:ss"
                  )}
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={newMoveObjTemp["moveInDate"] == "" && submitted}
                  helperText={
                    newMoveObjTemp["moveInDate"] == ""
                      ? "Move In Date required."
                      : "" && submitted
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Read Amt"
                  type="number"
                  onChange={onFieldChange}
                  name="meterRead"
                  defaultValue="0"
                  //required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText="Leave as 0 to auto generate estimate."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={readTypeChecked == "ACT" ? true : false}
                      onChange={(e) => {
                        handleReadTypeChange("ACT");
                        onFieldChange(e);
                      }}
                      name="readAmtType"
                      value="ACT"
                    />
                  }
                  label="ACT Read"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={readTypeChecked == "EST" ? true : false}
                      onChange={(e) => {
                        handleReadTypeChange("EST");
                        onFieldChange(e);
                      }}
                      name="readAmtType"
                      value="EST"
                    />
                  }
                  label="EST Read"
                />
              </Grid>
            </Grid>

            <Grid container item spacing={2} justify={"flex-end"}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    console.log("State??!?!?!?: ", state);
                    action(newMoveObjTemp, state.billingSame, unitInfo);                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div >
  );
};
